.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .avatar {
    height: 6em;
  }
}
